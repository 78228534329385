@import "src/styles/colors.scss";

.text-field-container {
    align-items: center;
    display: flex;
    border-radius: 6px;
    position: absolute;
    z-index: 10;

    .text-field-input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 6px;
    }

    .icon-container {
        position: absolute;
        right: 0px;
        opacity: 0;
        transition: opacity 0.3s;
        transform: translateX(100%);
        background-color: white
        
    }

    &:hover .icon-container {
        opacity: 1;
        cursor: pointer;
    }
}
