@import 'src/styles/colors.scss';

.signature-field-container {
    align-items: center;
    display: flex;
    background-color: $textFieldOverrideAlwaysBackgroundColor;
    border-radius: 6px;
    position: absolute;
    z-index: 10;
    cursor: pointer;

    .signature-field-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}
