@font-face {
    font-family: "Arabella";
    src: local("Arabella"),
     url("../assets/fonts/Arabella/Arabella.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "GreatVibes-Regular";
    src: local("GreatVibes-Regular"),
     url("../assets/fonts/GreatVibes/GreatVibes-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Montez-Regular";
    src: local("Montez-Regular"),
     url("../assets/fonts/Montez/Montez-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Sacramento-Regular";
    src: local("Sacramento-Regular"),
     url("../assets/fonts/Sacramento/Sacramento-Regular.ttf") format("truetype");
    font-weight: normal;
}